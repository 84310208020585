<template>
  <div class="mt-2">
    <b-container class="mb-2">
      <b-row class="d-flex justify-content-between">
        <b-col
          :class="[hoveredCol1 ? 'bg-colorGreen ' : 'bg-colorGrey ']"
          @mouseover="hoveredCol1 = true"
          @mouseleave="hoveredCol1 = false"
          class="py-2 image-container-hover"
          style="border-radius: 20px"
        >
          <div class="text-center">
            <img
              class="base-img"
              src="@/assets/images/icons/contactUs/location.svg"
              img-fluid
            />

            <img
              class="hover-img"
              src="@/assets/images/icons/contactUs/location white.svg"
              img-fluid
            />
          </div>

          <div class="pt-2 px-3">
            <h6 class="font-weight-bolder text-colorText text-center">
              AWT Plaza 1st Floor, AWT Plaza, Mall Road, Sadder, Rawalpindi,
              Pakistan
            </h6>
          </div>
        </b-col>
        <b-col
          :class="[hoveredCol2 ? 'bg-colorGreen' : 'bg-colorGrey']"
          @mouseover="hoveredCol2 = true"
          @mouseleave="hoveredCol2 = false"
          md=""
          class="py-2 ml-0 mt-1 mt-md-0 ml-md-1 image-container-hover"
          style="border-radius: 20px"
        >
          <div class="text-center">
            <img
              src="@/assets/images/icons/contactUs/email.svg"
              img-fluid
              class="base-img"
            />
            <img
              src="@/assets/images/icons/contactUs/email white.svg"
              img-fluid
              class="hover-img"
            />
          </div>
          <div class="pt-2 px-3">
            <h6 class="font-weight-bolder text-colorText text-center">
              medtours@medask.com.pk
            </h6>
          </div>
        </b-col>
        <b-col
          :class="[hoveredCol3 ? 'bg-colorGreen' : 'bg-colorGrey']"
          @mouseover="hoveredCol3 = true"
          @mouseleave="hoveredCol3 = false"
          md=""
          class="py-2 ml-0 mt-1 mt-md-0 ml-md-1 image-container-hover"
          style="border-radius: 20px"
        >
          <div class="text-center">
            <img
              src="@/assets/images/icons/contactUs/phone.svg"
              img-fluid
              class="base-img"
            />
            <img
              src="@/assets/images/icons/contactUs/phone white.svg"
              img-fluid
              class="hover-img"
            />
          </div>
          <div class="pt-2 px-3">
            <h6 class="font-weight-bolder text-colorText text-center">
              +92 319 6048487
            </h6>
          </div>
        </b-col>
        <!-- <b-col
          md=""
          :class="[hoveredCol4 ? 'bg-colorGreen' : 'bg-colorGrey']"
          @mouseover="hoveredCol4 = true"
          @mouseleave="hoveredCol4 = false"
          class="py-2 ml-0 mt-1 mt-md-0 ml-md-1 image-container-hover text-center"
          style="border-radius: 20px"
        >
          <img
            src="@/assets/images/icons/contactUs/time.svg"
            img-fluid
            class="base-img"
          />
          <img
            src="@/assets/images/icons/contactUs/time white.svg"
            img-fluid
            class="hover-img"
          />

          <div class="pt-2 px-3">
            <h6 class="font-weight-bolder text-colorText text-center">
              09:00 AM to 18:00 PM <br />
              Sunday to Thursday
            </h6>
          </div>
        </b-col> -->
      </b-row>
    </b-container>
    <b-container class="mb-2">
      <h3 class="font-weight-bolder text-colorBlue text-center py-2">
        Fill In Your Information & We Will Be In Touch As Soon As We Can
      </h3>
      <b-row class="d-flex justify-content-start align-items-center p-0 m-0">
        <b-col lg="5" md="7" sm="12" class="">
          <img
            src="@/assets/images/contactUs/4.jpeg"
            class="mb-2 mb-md-0 img-fluid rounded h-100"
          />
        </b-col>
        <b-col md="5" lg="7" sm="12" class="m-0">
          <b-card class="m-0 p-0 h-100">
            <validation-observer ref="contactUsPersonalCreateFormValidation">
              <b-form @submit.prevent>
                <b-row>
                  <b-col md="12">
                    <b-form-group label-for="name">
                      <template #label>
                        Name <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        :rules="{ required }"
                      >
                        <b-form-input
                          id="name"
                          v-model="name"
                          v-restrict="regex"
                          :state="errors.length > 0 ? false : null"
                          name="name"
                          placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="mobilenumber">
                      <template #label>
                        Contact Number <span class="text-danger">*</span>
                      </template>

                      <VuePhoneNumberInput
                        v-model="mobileNo"
                        @update="onVuePhoneNumberUpdate"
                        :fetch-country="true"
                        :required="true"
                        color="#06bd46"
                        valid-color="#06bd46"
                        error-color="#ea5455"
                        :error="
                          mobileNoData !== null
                            ? !mobileNoData['isValid']
                            : false
                        "
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="email">
                      <template #label>
                        Email <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="email"
                          v-model="email"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="12">
                    <b-form-group label-for="referenceNumber">
                      <template #label>
                        Reference Number <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Reference Number"
                        rules="integer"
                      >
                        <b-form-input
                          id="referenceNumber"
                          v-model="referenceNumber"
                          :state="errors.length > 0 ? false : null"
                          name="referenceNumber"
                          placeholder="Reference Number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <p class="font-weight-bolder text-danger p-0 m-0">
                      If reference number provided enter your reference number
                      otherwise leave it empty.
                    </p>
                  </b-col>
                  <b-col md="12">
                    <b-form-group :label-for="message">
                      <template #label> Message </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Messsage"
                        :rules="{ required }"
                      >
                        <b-form-textarea
                          id="message"
                          v-model="contactMessage"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Message"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <b-form-checkbox v-model="agreeToTerms" required>
                        I agree to MedAsk Tours Pakistan's
                        <b-link
                          :to="{ name: 'TermsOfServices' }"
                          class="text-colorGreen"
                        >
                          Terms and Conditions
                        </b-link>
                        , I have read the
                        <b-link
                          :to="{ name: 'PrivacyPolicy' }"
                          class="text-colorGreen"
                          >Privacy Policy</b-link
                        >
                        and I consent to the processing of my provided details,
                        including health data, by MedAsk Tours Pakistan for the
                        purpose of obtaining quotes.
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>

                <div class="text-center">
                  <b-button
                    type="submit"
                    variant="success"
                    class=""
                    :disabled="!agreeToTerms"
                    @click="validateForm"
                  >
                    Submit
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getAllCountries } from "countries-and-timezones";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { mapActions } from "vuex";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Country, State, City } from "country-state-city";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
    ToastificationContent,
    FeatherIcon,
    vueDropzone: vue2Dropzone,
    Country,
    State,
    City,
    VuePhoneNumberInput,
  },
  mixins: [util],

  data() {
    return {
      procedures: null,
      agreeToTerms: false,
      name: "",
      email: "",
      mobileNo: "",
      country: "",
      referenceNumber: "",
      state: "",
      city: "",
      message: "",
      postalCode: "",
      treatment: null,
      treatments: [],
      carRental: false,
      visaAssistance: false,
      hotel: false,
      tourism: false,
      insurance: false,
      safeTravel: false,
      interpreterService: false,
      gender: "",
      age: "",
      contactMessage: "",
      files: [],
      mobileNoData: null,
      agreeToTerms: false,
      isFormValid: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
      },
      required: required,
      countries: [], // Initialize countries as an empty array
      states: [],
      cities: [],
      selectedCountry: null,
      selectedState: null,
      selectedCity: null,
      formWizardKey: 0,
      regex: /^[a-zA-Z ]*$/,
      hoveredCol2: false,
      hoveredCol1: false,
      hoveredCol3: false,
      hoveredCol4: false,
    };
  },
  async mounted() {
    this.loadCountries();
    // this.fetchData();
  },

  computed: {
    treatmentNames() {
      return this.treatments.map((item) => item.name);
    },
  },
  methods: {
    ...mapActions({
      getprocedures: "appData/getProceduresPublicNav",
      createQuote: "appData/createQuote",
    }),
    loadCountries() {
      const countries = getAllCountries();
      this.countries = Object.values(countries).map((country) => ({
        name: country.name,
        code: country.id,
      }));
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.states = State.getStatesOfCountry(country.code);
      this.cities = [];
      this.selectedState = null;
      this.selectedCity = null;
    },
    selectState(state) {
      this.selectedState = state;
      this.cities = City.getCitiesOfState(
        this.selectedCountry.code,
        state.isoCode
      );

      this.selectedCity = null;
    },
    selectCity(city) {
      this.selectedCity = city;
    },
    onVuePhoneNumberUpdate(data) {
      this.mobileNoData = data;
    },
    async validateForm() {
      const success =
        await this.$refs.contactUsPersonalCreateFormValidation.validate();
      if (success && this.mobileNoData["isValid"]) {
        await this.submit();
      }
    },
    // async medicalValidationForm() {
    //   return this.$refs.contactUsMedicalCreateFormValidation.validate();
    // },
    onVuePhoneNumberUpdate(data) {
      this.mobileNoData = data;
    },
    async beforeChange() {
      const validationObserver = this.$refs.contactUsCreateFormValidation;
      const isValid = await validationObserver.validate();
      if (!isValid) {
        this.isFormValid = false;
        return false;
      } else {
        this.isFormValid = true;
        return true;
      }
    },

    // async fetchData() {
    //   const res_procedures = await this.getprocedures({});
    //   this.treatments = res_procedures.data.map((item) => {
    //     return {
    //       name: item.name,
    //       id: item.id,
    //     };
    //   });

    //   console.log(this.treatments, "ewqewewq");
    // },

    async submit() {
      // if (this.gender.text === "") {
      //   gender.text = "";
      // }
      let formattedMobileNo = this.mobileNoData["formatInternational"].replace(
        /[\s()+-]/g,
        ""
      );
      try {
        const bookingData = {
          full_name: this.name,
          mobile: formattedMobileNo,
          email: this.email,
          referral_code: this.referenceNumber,
          // country: this.selectedCountry.name,
          // state: this.selectedState.name,
          // city: this.selectedCity.name,
          // postal_code: this.postalCode,
          // procedure: this.treatment,
          // gender: this.gender ? this.gender : "",
          // age: this.age,
          message: this.contactMessage,
          // car_rental: this.carRental,
          // visa_assistance: this.visaAssistance,
          // hotel_booking: this.hotel,
          // tourism: this.tourism,
          // insurance: this.insurance,
          // safe_travel: this.safeTravel,
          // interpreter_service: this.interpreterService,
        };

        // Append booking data to FormData
        const formData = new FormData();
        for (const key in bookingData) {
          formData.append(key, bookingData[key]);
        }
        // Append files for booking reports
        // for (const file of this.files) {
        //   formData.append("images", file);
        // }

        // Send booking data and reports
        const response = await this.createQuote(formData);

        if (response.status === 200) {
          this.$swal(
            {
              title:
                "Thank you for contacting Medask Tours! We’ll get back to you shortly.",
              icon: "success",
            },
            {
              position: "top-right",
            }
          );
          this.reset();
          // this.formWizardKey += 1;
        } else {
          this.$swal(
            {
              title:
                "Oops! Something went wrong with your submission. Please try again or contact us directly at medtours@medask.com.pk.",
              variant: "danger",
              icon: "BellIcon",
            },
            {
              position: "top-right",
            }
          );
        }
      } catch (error) {
        this.displayError(error);
        console.log(error);
      }
    },
    reset() {
      // this.procedures = null;
      this.name = "";
      this.email = "";
      this.mobileNo = "";
      this.referenceNumber = "";
      // this.country = "";
      // this.state = "";
      // this.city = "";
      // this.postalCode = "";
      // this.treatment = null;
      // this.carRental = false;
      // this.visaAssistance = false;
      // this.hotel = false;
      // this.tourism = false;
      // this.insurance = false;
      // this.safeTravel = false;
      // this.interpreterService = false;
      // this.gender = "";
      // this.age = "";
      this.contactMessage = "";
      // this.files = [];
      // this.selectedCity = null;
      // this.selectedCountry = null;
      // this.selectedState = null;
    },
  },
};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/contactUs/2.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 380px;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

[dir] .col-form-label {
  padding-bottom: 0;
}
.hover-img {
  display: none;
}
.image-container-hover {
  display: block;
}
.image-container-hover:hover .hover-img {
  display: block;
  margin: 0 auto;
}

.image-container-hover:hover .base-img {
  display: none;
}
</style>
